<template>
	<div
		class="my-5 mr-1 text-center quote-block"
		:style="[
			!bidData.isBidAcceptedRejected && bidData.show_change_price
				? { 'min-height': '185px' }
				: bidData.object_type == 'new_quote'
				? { 'min-height': '155px' }
				: { 'min-height': '140px' },
		]"
	>
		<!-- "
		v-bind:style="[!bidData.isBidAcceptedRejected && bidData.show_change_price ? (bidData.object_type == 'new_quote' ? {'min-height': '220px' }  : { 'min-height': '160px' } : { 'min-height': '160px' }) ]"
		-->
		<div class="mb-4 pa-2" style="border: 1px solid #ebeef6; border-radius: 6px">
			<div v-if="bidData.object_type == 'discounted_quote'" style="background: #e2efff; border-radius: 4px; height: '150px'" class="pa-4">
				<div class="d-flex align-center justify-space-between mb-4">
					<div class="new-price-label">Your New Price</div>
					<div class="new-price-value">{{ this.$options.filters.currency(bidData.total_amount) }}</div>
				</div>
				<div class="d-flex align-center justify-space-between discount">
					<div class="discount-label">Discount</div>
					<div class="discount-value">{{ bidData.discountPercentage }}</div>
				</div>
			</div>

			<div v-if="bidData.object_type == 'new_quote'">
				<div class="pt-4 pb-6 quote-for">Your Quote for {{ userName }}</div>
				<div class="d-flex align-center justify-space-between pa-4" style="background: #e2efff; border-radius: 4px; height: '150px'">
					<div class="last-price">Your Last Price</div>

					<div class="amount">{{ this.$options.filters.currency(bidData.total_amount) }}</div>
				</div>
			</div>
		</div>
		<v-btn
			v-if="!bidData.isBidAcceptedRejected && bidData.show_change_price"
			class="btn-primary my_elevation"
			block
			id="my_elevation"
			color="#2974FF"
			height="56"
			depressed
			@click="displayChangeBid"
			>Change Price</v-btn
		>
	</div>
</template>

<script>
export default {
	props: {
		bidobj: {
			type: Object,
			default: {},
		},
		sender: {
			type: String,
			default: '',
		},
		id: {
			type: String,
			default: '',
		},
		userName: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			bidData: {},
			height: 200,
		}
	},

	mounted() {
		this.bidData = this.bidobj
	},

	watch: {
		bidobj: {
			deep: true,
			handler() {
				Object.assign(this.bidData, this.bidobj)
			},
		},
	},

	methods: {
		displayChangeBid() {
			this.$emit('displayChangeBid', this.$store.state.chathaggle.active_bid_id)
			this.$store.state.chathaggle.bid_obj_id = this.id
		},
	},
}
</script>

<style lang="scss" scoped>
.quote-block {
	font-family: 'Inter-Regular';
}

.quote-for {
	color: #2974ff;
	font-size: 14px;
	line-height: 14px;
}

.last-price {
	font-size: 15px;
	line-height: 15px;
	color: #212121;
}

.amount {
	font-weight: 100;
	font-size: 18px;
	line-height: 22px;
	color: #414141;
}

.discount-block {
	font-size: 15px;
	line-height: 15px;
	color: #212121;
	font-family: 'Inter-Medium';
	.new-price {
		&-value {
			font-weight: 100;
			line-height: 18px;
			font-family: 'Inter-Light';
		}
	}

	.discount {
		font-size: 13px;

		&-value {
			line-height: 16px;
		}
	}
}
</style>
